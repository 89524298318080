/* eslint-disable @nx/enforce-module-boundaries */
import {
  AppointmentsIcon,
  CalendarIcon,
  CalendarNewIcon,
  MyCalendarSideBar,
  EventIcon,
  RecordingIcon,
  MyEventsIcon,
  UpcomingMeetingsIcon,
  EventIconColored,
  JoinMeetingColoredIcon,
  JoinMeetingIcon,
  RecordVideoCameraIcon,
  ScheduleIcon,
  AppointmentScheduleIcon,
} from '@mybridge/icons';
import {
  Box,
  Button,
  Card,
  Divider,
  Heading,
  VStack,
  Circle,
} from '@mybridge/ui';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import {
  joinMeeting,
  setJoinMeetNow,
  setMeetNow,
} from 'v4/store/actions/meeting.actions';
import UpcomingMeetings from './upcoming-meetings';
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
const CalendarSidebar = ({ isDashboard, ...props }) => {
  const dispatch = useDispatch();
  const { pathname } = useRouter();
  const { unreadMeetingCount } = useSelector((state) => state.globalData);
  // console.log('pathname', pathname);
  return (
    <>
      {isMobile ? (
        <>
          <Card
            _hover={{ bg: 'gray.200' }}
            variant="lightBgCard"
            p={2}
            w="100%"
          >
            <Button
              onClick={(e) => dispatch(setMeetNow(true))}
              variant="transparent"
              leftIcon={<EventIconColored width="20" height="20" />}
              justifyContent="flex-start"
            >
              Meet Now
            </Button>
          </Card>
          <Card _hover={{ bg: 'gray.200' }} variant="lightBgCard" p={2}>
            <Button
              onClick={(e) => dispatch(setJoinMeetNow(true))}
              variant="transparent"
              leftIcon={<JoinMeetingIcon />}
              justifyContent="flex-start"
            >
              Join a Meeting
            </Button>
          </Card>
          <Card _hover={{ bg: 'gray.200' }} variant="lightBgCard" p={2}>
            <Link href="/profile/calendar/schedule">
              <Button
                variant="transparent"
                leftIcon={<ScheduleIcon width="27" height="27" />}
                justifyContent="flex-start"
              >
                Schedule a Meeting
              </Button>
            </Link>
          </Card>
          <UpcomingMeetings isDashboard={false} />
        </>
      ) : (
        <>
          {!isDashboard && (
            <VStack align="stretch" p={3}>
              <Heading as="h4" color="#3B718D" fontSize="18px" mb={4}>
                Calendar
              </Heading>
              <Link href="/profile/calendar/">
                <Button
                  variant="ghost"
                  leftIcon={
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <MyCalendarSideBar
                        color="#575757"
                        width="20"
                        height="20"
                      />
                    </Box>
                  }
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={pathname === '/profile/calendar' ? true : false}
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '600',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '600',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  myCalendar
                </Button>
              </Link>

              <Link href="/profile/calendar/calendar-listing">
                <Button
                  // onClick={(e) => dispatch(setJoinMeetNow(true))}
                  variant="ghost"
                  leftIcon={
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <UpcomingMeetingsIcon
                        color="#575757"
                        width="20"
                        height="20"
                      />
                    </Box>
                  }
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={
                    pathname === '/profile/calendar/calendar-listing'
                      ? true
                      : false
                  }
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '600',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '600',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  Upcoming meeting
                  {unreadMeetingCount > 0 && (
                    <Circle
                      bg="brandRed.500"
                      color="white"
                      w="20px"
                      h="20px"
                      p="8px"
                      borderRadius="100%"
                      ml="8px"
                    >
                      {unreadMeetingCount}
                    </Circle>
                  )}
                </Button>
              </Link>

              <Link href="/profile/calendar/appoinments">
                <Button
                  // onClick={(e) => dispatch(setJoinMeetNow(true))}
                  variant="ghost"
                  leftIcon={
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <AppointmentsIcon />
                    </Box>
                  }
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={
                    pathname === '/profile/calendar/appoinments' ? true : false
                  }
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '600',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '600',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  Appointments
                </Button>
              </Link>

              <Link href="/profile/calendar/recordings">
                <Button
                  variant="ghost"
                  leftIcon={
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <RecordingIcon width="20" height="20" color="#575757" />
                    </Box>
                  }
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  mb={4}
                  w="100%"
                  isActive={
                    pathname === '/profile/calendar/recordings' ? true : false
                  }
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '600',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '600',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  Recordings
                </Button>
              </Link>
              <Link href="/myevents">
                <Button
                  variant="ghost"
                  leftIcon={
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <MyEventsIcon width="20" height="20" color="#575757" />
                    </Box>
                  }
                  justifyContent="flex-start"
                  fontWeight="400"
                  color="#575757"
                  w="100%"
                  isActive={pathname === '/myevents' ? true : false}
                  _active={{
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '600',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  }}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EDEDED',
                      cursor: 'pointer',
                      // color: 'brandPrimary.500',
                      fontWeight: '600',
                      boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                      // path: {
                      //   fill: 'brandPrimary.500',
                      // },
                    },
                  }}
                >
                  myEvent
                </Button>
              </Link>
            </VStack>
          )}
          {!isDashboard && <Divider />}
          <VStack align="stretch" p={3}>
            <Button
              onClick={(e) => dispatch(setMeetNow(true))}
              variant="ghost"
              height="50px"
              paddingInlineStart={isDashboard ? '0px' : '20px'}
              leftIcon={
                isDashboard ? (
                  <Box
                    borderRadius="full"
                    bg="#D9D9D9"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="35px"
                    h="35px"
                  >
                    <EventIconColored width="20" height="20" />
                  </Box>
                ) : (
                  <EventIconColored width="20" height="20" />
                )
              }
              justifyContent="flex-start"
              fontWeight="600"
              color="#3D5A80"
              sx={{
                ':hover': {
                  backgroundColor: 'brandGray.500',
                  cursor: 'pointer',
                  // color: 'brandPrimary.500',
                  bg: '#C6C6C6',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                  fontWeight: '600',
                  path: {
                    // fill: 'brandPrimary.500',
                  },
                },
              }}
            >
              Meet Now  
              {/* meet now menu */}
            </Button>
            <Button
              onClick={(e) => dispatch(setJoinMeetNow(true))}
              variant="ghost"
              height="50px"
              paddingInlineStart={isDashboard ? '0px' : '20px'}
              leftIcon={
                isDashboard ? (
                  <Box
                    borderRadius="full"
                    bg="#D9D9D9"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="35px"
                    h="35px"
                  >
                    <JoinMeetingColoredIcon width="20" height="20" />
                  </Box>
                ) : (
                  <JoinMeetingColoredIcon width="20" height="20" />
                )
              }
              justifyContent="flex-start"
              fontWeight="600"
              color="#3D5A80"
              sx={{
                ':hover': {
                  backgroundColor: 'brandGray.500',
                  cursor: 'pointer',
                  // color: 'brandPrimary.500',
                  fontWeight: '600',
                  bg: '#C6C6C6',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                  path: {
                    // fill: 'brandPrimary.500',
                  },
                },
              }}
            >
              Join a Meeting
            </Button>
            <Link href="/profile/calendar/schedule">
              <Button
                variant="ghost"
                height="50px"
                paddingInlineStart={isDashboard ? '0px' : '20px'}
                leftIcon={
                  isDashboard ? (
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <CalendarNewIcon width="20" height="20" />
                    </Box>
                  ) : (
                    <CalendarNewIcon width="20" height="20" />
                  )
                }
                justifyContent="flex-start"
                fontWeight="600"
                color="#3D5A80"
                w="100%"
                isActive={
                  pathname === '/profile/calendar/[slug]' ? true : false
                }
                _active={{
                  backgroundColor: '#C6C6C6',
                  cursor: 'pointer',
                  color: '#3D5A80',
                  fontWeight: '600',
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                  // path: {
                  //   fill: 'brandPrimary.500',
                  // },
                }}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                    fontWeight: '600',
                    backgroundColor: '#C6C6C6',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    path: {
                      // fill: 'brandPrimary.500',
                    },
                  },
                }}
              >
                Schedule a Meeting
              </Button>
            </Link>
            <Link href="/profile/calendar/appoinments">
              <Button
                // onClick={(e) => dispatch(setJoinMeetNow(true))}
                variant="ghost"
                paddingInlineStart={isDashboard ? '0px' : '20px'}
                leftIcon={
                  isDashboard ? (
                    <Box
                      borderRadius="full"
                      bg="#D9D9D9"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      w="35px"
                      h="35px"
                    >
                      <AppointmentScheduleIcon />
                    </Box>
                  ) : (
                    <AppointmentScheduleIcon />
                  )
                }
                justifyContent="flex-start"
                fontWeight="600"
                color="#3D5A80"
                mb={4}
                w="100%"
                isActive={
                  pathname === '/profile/calendar/appoinments' ? true : false
                }
                sx={{
                  ':hover': {
                    backgroundColor: '#EDEDED',
                    cursor: 'pointer',
                    // color: 'brandPrimary.500',
                    fontWeight: '600',
                    boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                    // path: {
                    //   fill: 'brandPrimary.500',
                    // },
                  },
                }}
              >
                Appointment Schedule
              </Button>
            </Link>
          </VStack>
        </>
      )}
    </>
  );
};

export default CalendarSidebar;
